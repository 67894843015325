/* eslint-disable */
import { FuseNavigationItem } from '@fuse/components/navigation';

export const defaultNavigation: FuseNavigationItem[] = [
    {
        id      : 'dashboards',
        title   : 'Dashboards',
        subtitle: 'Camera Management',
        type    : 'group',
        icon    : 'heroicons_outline:home',
        children: [
            {
                id   : 'dashboards.project',
                title: 'NVR',
                type : 'basic',
                icon : 'heroicons_outline:clipboard-document-check',
                link : '/dashboards/project',
            },
            {
                id   : 'dashboards.analytics',
                title: 'Analytics',
                type : 'basic',
                icon : 'heroicons_outline:chart-pie',
                link : '/dashboards/analytics',
            },
            {
                id   : 'dashboards.alerts',
                title: 'Alerts',
                type : 'basic',
                icon : 'feather:alert-triangle',
                link : '/dashboards/alerts',
            },
            {
                id   : 'dashboards.users',
                title: 'Users',
                type : 'basic',
                icon : 'heroicons_outline:users',
                link : '/dashboards/users',
            },
            // {
            //     id   : 'dashboards.finance',
            //     title: 'Finance',
            //     type : 'basic',
            //     icon : 'heroicons_outline:banknotes',
            //     link : '/dashboards/finance',
            // },
            // {
            //     id   : 'dashboards.crypto',
            //     title: 'Crypto',
            //     type : 'basic',
            //     icon : 'heroicons_outline:currency-dollar',
            //     link : '/dashboards/crypto',
            // },
        ],
    },
];
export const compactNavigation: FuseNavigationItem[] = defaultNavigation;
export const futuristicNavigation: FuseNavigationItem[] = defaultNavigation;
export const horizontalNavigation: FuseNavigationItem[] = defaultNavigation;
