import {
    HttpClient,
    HttpErrorResponse,
    HttpEventType,
    HttpHeaders,
    HttpParams,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import { CommonService } from './common.services';

interface NetaResponse {
    status: string;
    msg: any;
}

@Injectable({ providedIn: 'root' })
export class BaseRequestService {
    cyberLabel: any;
    authHeader = new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: btoa(window.location.host),
    });
    resources: any = {};
    companyId: any;
    helpLinks: any;
    enckey: any;

    constructor(
        readonly httpClient: HttpClient,
        private titleService: Title,
        public cs: CommonService
    ) {}

    public upload(url: string, data: any): any {
        return this.httpClient
            .post<any>(url, data, {
                reportProgress: true,
                observe: 'events',
            })
            .pipe(
                map((event) => {
                    switch (event.type) {
                        case HttpEventType.UploadProgress:
                            // @ts-ignore
                            const progress = Math.round(
                                (100 * event.loaded) / event.total
                            );
                            return { status: 'progress', message: progress };
                        case HttpEventType.Response:
                            return event.body;
                        default:
                            return `Unhandled event: ${event.type}`;
                    }
                })
            );
    }

    public getClientData(): any {
        return this.httpClient.get('https://extreme-ip-lookup.com/json/');
    }

    private getEncryptKey(): void {
        this.doRequest(
            '/api/cyberutils/dummy/getEncryptionKey',
            'post',
            {},
            null,
            this.authHeader
        ).subscribe((data: any) => {
            this.enckey = atob(data.msg);
        });
    }

    public setCurrentCompany(companyId: any): void {
        this.companyId = companyId;
    }

    public nonce(): number | string {
        let val = '';
        const hex = 'abcdefghijklmnopqrstuvwxyz0123456789';
        for (let i = 0; i < 16; i++) {
            val += hex.charAt(Math.floor(Math.random() * hex.length));
        }
        return val;
    }

    toHttpParams(obj: any): any {
        const params = {};
        for (const key in obj) {
            if (obj.hasOwnProperty(key)) {
                const val = obj[key];
                if (val !== null && val !== undefined) {
                    if (typeof val === 'object') {
                        // @ts-ignore
                        params[key] = JSON.stringify(val);
                    } else {
                        // @ts-ignore
                        params[key] = val.toString();
                    }
                }
            }
        }
        return params;
    }

    generateRandomKeyWithTime(): string {
        // Generate a random string using crypto
        const randomString = this.generateRandomString(16);

        // Get the current timestamp in a specific format
        const now = new Date();
        const pad = (num: number, size: number) => num.toString().padStart(size, '0');
        const currentTime = `${now.getFullYear()}${pad(now.getMonth() + 1, 2)}${pad(now.getDate(), 2)}${pad(now.getHours(), 2)}${pad(now.getMinutes(), 2)}${pad(now.getSeconds(), 2)}${pad(now.getMilliseconds(), 3)}`;

        // Combine the random string and the current time
        return `${randomString}_${currentTime}`;
    }


    private generateRandomString(length: number): string {
        const array = new Uint32Array(length);
        window.crypto.getRandomValues(array);
        return Array.from(array, (dec) =>
            ('0' + dec.toString(16)).substr(-2)
        ).join('');
    }

    doRequest(
        endPointUrl: string,
        method: string,
        data?: any,
        params?: any,
        headers?: HttpHeaders,
        hashOptions?: any
    ): any {
        const httpOptions = {
            headers: headers
                ? headers
                : new HttpHeaders({
                      'Content-Type': 'application/json',
                      'dp-api': this.generateRandomKeyWithTime(),
                  }),
            body: JSON.stringify(data),
            params,
        };
        if (params) {
            httpOptions.params = new HttpParams({
                fromObject: this.toHttpParams(params),
            });
        }
        try{
            const token = JSON.parse(localStorage.getItem('accessToken'));
            if (!httpOptions.headers.has('AUTHORIZATION') && token) {
                httpOptions.headers = httpOptions.headers.append(
                    'AUTHORIZATION',
                    `Bearer ${token}`
                );
            }
            if (!httpOptions.headers.has('APPLICATION') && token) {
                httpOptions.headers = httpOptions.headers.append(
                    'APPLICATION', 'IOTPORTAL'
                );
            }
        } catch (e) {
            // console.log(e);
        }
        // @ts-ignore
        httpOptions.headers.hashOptions = hashOptions
            ? hashOptions
            : { isLoading: false };
        const uri = `${this.cs.baseurl}${endPointUrl}`;
        return this.httpClient
            .request<NetaResponse>(method, `${uri}`, httpOptions)
            .pipe(map((response) => this.handleResponse(response)));
    }

    getSnakeCaseName(camelCase: string): string {
        return camelCase
            .replace(/([A-Z])/g, '_$1')
            .toLowerCase()
            .replace(/^_(.*)/g, '$1');
    }

    private handleResponse(response: NetaResponse): NetaResponse {
        return response;
    }

    private handleError(error: HttpErrorResponse): any {
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error(
                `Backend returned code ${error.status}, ` +
                    `body was: ${error.error}`
            );
        }
        // return an observable with a user-facing error message
        return throwError('Something bad happened; please try again later.');
    }
}
