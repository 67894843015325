import {NgModule} from "@angular/core";
import {CommonModule, DatePipe} from "@angular/common";
import {
    CamelToHumanPipe,
    DaysHoursSecondsPipe,
    BytesConvertFilterPipe,
    EpochToDateFilterPipe,
    StrToMacPipe,
    SafePipe,
    ArrayOrderByPipe,
    FormatTrafficUnitsPipe,
    DateAgoPipe,
    FormatCellPipe,
    UniqueFilterPipe,
    ArrayToStrPipe,
    TimeFormat,
    ToLocale,
    DateFormat,
    DateAndTimeFormat,
    CveToDateFilterPipe,
    MacFilterPipe,
    AssessmentDateFilterPipe,
    SplitPipe,
    FilterPipe,
    ArrayToStrEllipsisPipe,
    SearchPipe,
    SafeHTMLPipe,
    TimeAgoPipe,
    ArraySortPipe,
    EllipsisPipe,
    ShortNumberPipe,
    UtcToLocale,
    FileNameFilterPipe,
    MaskPasswordFilterPipe,
    ArrayToObjWithEllipsisPipe,
    DateToDaysFilterPipe,
    EnumFilterPipe,
    NoneToHyphen,
    UtcTimeAgoPipe,
    UppercasePipe,
    Iso8601ToLocalTimePipe,
    CleanStringPipe,
    EnumStringPipe,
    TagsPipe,
    ManualTagsPipe,
    DecodeHtmlEntitiesPipe,
    ToFixedPipe,
    TTimePipe,
    DecodeHtmlStrPipe,
    UtcToLocaleRDate,
    UtcToLocaleDate,
    KeyTransformPipe,
    ArrayLengthPipe,
    RemeActionPipe,
    ObjectToTimeFilterPipe,
    TextBadgePipe,
    UtcToLocaleHTML,
    DraftToCompleteFilterPipe,
    PortsDataFilterPipe,
    CapitalSplitPipe,
    ReportNameDataPipe,
    VelocityFilterPipe,
    FirewallTypePipe,
    AppBaseLineFilterPipe,
    SecurityGradePipe,
    DiscoveryProtocolPipe,
    AssetStatusFilterPipe,
    FindByKeyPipe, HighlightFilterPipe,
    HostnameToDomainPipe,
    Date_Format
} from "./app.filter.pipe";

@NgModule({
    declarations: [
        CamelToHumanPipe,
        KeyTransformPipe,
        DaysHoursSecondsPipe,
        BytesConvertFilterPipe,
        EpochToDateFilterPipe,
        StrToMacPipe,
        SafePipe,
        ToLocale,
        MacFilterPipe,
        ArrayOrderByPipe,
        AssessmentDateFilterPipe,
        ArrayLengthPipe,
        SplitPipe,
        ArrayToStrEllipsisPipe,
        SearchPipe,
        FormatTrafficUnitsPipe,
        SafeHTMLPipe,
        TimeAgoPipe,
        ArraySortPipe,
        EllipsisPipe,
        ShortNumberPipe,
        UtcToLocale,
        FileNameFilterPipe,
        MaskPasswordFilterPipe,
        ArrayToObjWithEllipsisPipe,
        DateToDaysFilterPipe,
        EnumFilterPipe,
        NoneToHyphen,
        UtcTimeAgoPipe,
        UppercasePipe,
        Iso8601ToLocalTimePipe,
        ObjectToTimeFilterPipe,
        DateAgoPipe,
        CleanStringPipe,
        Date_Format,
        HostnameToDomainPipe,
        ToFixedPipe,
        TTimePipe,
        DecodeHtmlStrPipe,
        EnumStringPipe,
        TagsPipe,
        ManualTagsPipe,
        DecodeHtmlEntitiesPipe,
        FormatCellPipe,
        UniqueFilterPipe,
        ArrayToStrPipe,
        RemeActionPipe,
        TimeFormat,
        DateFormat,
        DateAndTimeFormat,
        CveToDateFilterPipe,
        FilterPipe,
        UtcToLocaleRDate,
        UtcToLocaleDate,
        UtcToLocaleHTML,
        TextBadgePipe,
        DraftToCompleteFilterPipe,
        PortsDataFilterPipe,
        CapitalSplitPipe,
        ReportNameDataPipe,
        VelocityFilterPipe,FirewallTypePipe, SecurityGradePipe,
        AppBaseLineFilterPipe,
        DiscoveryProtocolPipe,
        AssetStatusFilterPipe,
        FindByKeyPipe, HighlightFilterPipe
    ],
    imports: [CommonModule],
    exports: [
        CamelToHumanPipe,
        DaysHoursSecondsPipe,
        BytesConvertFilterPipe,
        EpochToDateFilterPipe,
        StrToMacPipe,
        KeyTransformPipe,
        SafePipe,
        MacFilterPipe,
        ArrayOrderByPipe,
        AssessmentDateFilterPipe,
        ArrayLengthPipe,
        SplitPipe,
        ToLocale,
        ArrayToStrEllipsisPipe,
        SearchPipe,
        FormatTrafficUnitsPipe,
        SafeHTMLPipe,
        TimeAgoPipe,
        ArraySortPipe,
        FileNameFilterPipe,
        MaskPasswordFilterPipe,
        ArrayToObjWithEllipsisPipe,
        DateToDaysFilterPipe,
        EnumFilterPipe,
        NoneToHyphen,
        UtcTimeAgoPipe,
        UppercasePipe,
        Iso8601ToLocalTimePipe,
        ObjectToTimeFilterPipe,
        DateAgoPipe,
        Date_Format,
        CleanStringPipe,
        HostnameToDomainPipe,
        ToFixedPipe,
        TTimePipe,
        DecodeHtmlStrPipe,
        EnumStringPipe,
        TagsPipe,
        ManualTagsPipe,
        DecodeHtmlEntitiesPipe,
        FormatCellPipe,
        UniqueFilterPipe,
        ArrayToStrPipe,
        TimeFormat,
        DateFormat,
        DateAndTimeFormat,
        CveToDateFilterPipe,
        RemeActionPipe,
        FilterPipe,
        EllipsisPipe,
        ShortNumberPipe,
        UtcToLocale,
        UtcToLocaleRDate,
        UtcToLocaleDate,
        ObjectToTimeFilterPipe,
        UtcToLocaleHTML,
        TextBadgePipe,
        DraftToCompleteFilterPipe,
        PortsDataFilterPipe,
        CapitalSplitPipe,
        ReportNameDataPipe,
        VelocityFilterPipe,FirewallTypePipe, SecurityGradePipe,
        AppBaseLineFilterPipe,
        DiscoveryProtocolPipe,
        AssetStatusFilterPipe,
        FindByKeyPipe, HighlightFilterPipe
    ],
    providers: [
        DatePipe,
        FilterPipe,
        ToLocale,
        CleanStringPipe,
        Date_Format,
        HostnameToDomainPipe,
        EnumStringPipe,
        TagsPipe,
        ManualTagsPipe,
        DecodeHtmlEntitiesPipe,
        DateAgoPipe,
        FormatCellPipe,
        BytesConvertFilterPipe,
        ArrayOrderByPipe,
        SearchPipe,
        EnumFilterPipe,
        UtcToLocaleHTML,
        DraftToCompleteFilterPipe,
    ],
})
export class AppFilterPipeModule {
}
